import React from "react";

export default function Default(props) {
  return (
    <div className="shadow-sm p-3 m-2">
      <p className={props.statusText === "none" ? "d-none" : "d-block"}>
        <i className="bi bi-search me-2"></i>
        <br />
        <span className="fs-4 fw-bold">{props.statusText}</span>
      </p>
      <hr className={props.statusText === "none" ? "d-none" : "d-block"} />
      <p className="small">
        Welcome to the <em>QR Code</em> page of Maxx's Group International, the
        URL address you entered seems to be invalid, please check again.
      </p>
    </div>
  );
}
