// import logo from "./logo.svg";
// import './App.css';
import Path from "./Routes/Router";

function App() {
  return (
    <div className="App">
      <Path />
    </div>
  );
}

export default App;
