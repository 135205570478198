import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API } from "../Config/Config";
import Default from "./Default";
import Data from "./Data";
import AppDateFormater from "../Components/AppDateFormatter";

export default function Layout() {
  const { code } = useParams();
  const [isEmpty, setIsEmpty] = useState(true);
  const [getResponseStatusText, setResponseStatusText] = useState();
  const [getCustomerId, setCustomerId] = useState();
  const [getFirstName, setFirstName] = useState();
  const [getLastName, setLastName] = useState();
  const [getNationality, setNationality] = useState();
  const [getGender, setGender] = useState();
  const [getExpiredDate, setExpiredDate] = useState();
  const [getStatus, setStatus] = useState();
  const [getPhoto, setPhoto] = useState();

  const newDate = new Date();
  const currentDate = newDate
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  useEffect(() => {
    // console.log(API + `/member/${code}`);
    if (`${code}` === `undefined`) {
      setResponseStatusText(`none`);
    } else {
      async function getQrData() {
        const headers = { "Content-Type": "application/json" };
        await axios
          .get(API + `/member/${code}`, { headers })
          .then(function (response) {
            // console.log(response);
            if (response.status === 200) {
              if (response.data.data[0] != null) {
                setIsEmpty(false);
                setExpiredDate(response.data.data[0].expired_date);
                setCustomerId(response.data.data[0].customer.id);
                setPhoto(response.data.data[0].customer.photo);
                setFirstName(response.data.data[0].customer.first_name);
                setLastName(response.data.data[0].customer.last_name);
                setGender(response.data.data[0].customer.param_gender.name);
                setNationality(
                  response.data.data[0].customer.param_nationality.name
                );
                setStatus(
                  currentDate.toString() <=
                    response.data.data[0].expired_date.toString()
                    ? "Active"
                    : "Inactive"
                );
              } else {
                setResponseStatusText(`Data Not Found !`);
              }
            } else {
              setResponseStatusText(response.statusText);
            }
          })
          .catch(function (error) {
            setResponseStatusText(error.code);
            // console.log(error.code);
          });
      }
      getQrData();
    }
  }, [code, currentDate]);

  return (
    <div>
      <header className="mb-auto">
        <div className="text-center">
          <img
            src="/assets/images/logo.png"
            alt="Maxx's Group International"
            height="65"
          />
        </div>
      </header>
      <main className="py-2 my-3 text-center">
        {isEmpty === true ? (
          <Default statusText={getResponseStatusText} />
        ) : (
          <Data
            id={getCustomerId}
            photo={getPhoto}
            firstName={getFirstName}
            lastName={getLastName}
            nationality={getNationality}
            gender={getGender}
            status={getStatus}
            expiredDate={AppDateFormater.beautyDate(getExpiredDate)}
          />
        )}
      </main>
      <footer className="mt-auto text-center">
        <p className="small mb-4">
          &copy; 2023
          <br />
          <strong>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://maxxsgroup.com"
              className="link-offset-2 link-underline link-underline-opacity-0"
            >
              Maxx's Group International
            </a>
          </strong>
          <br />
          All rights reserved
          <br />
          <small className="em">
            <em>version 1.0.3</em>
          </small>
        </p>
        <p className="small mb-0">
          Lippo Kuningan Building 19<sup>th</sup> Floor, Unit B<br />
          Jl. HR. Rasuna Said, Kav. B12, Kuningan, Setiabudi
          <br />
          Jakarta Selatan, DKI Jakarta, Indonesia 12940
        </p>
        <p className="small">
          Telp. +62 21 5081 3027 | Email. hello@maxxsgroup.com
        </p>
      </footer>
    </div>
  );
}
