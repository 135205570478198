import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../Views/Layout";

export default function Path() {
  return (
    <Routes>
      <Route path="/" element={<Layout />} />
      <Route path="/member/:code" element={<Layout />} />
    </Routes>
  );
}
