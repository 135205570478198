// ---------- API Development -----------------------------//
// export const API = "http://localhost:8000/api";
// export const API = "http://192.168.1.12:8000/api";
// export const API = "http://192.168.1.50:8000/api";
// export const API = "http://192.168.1.103:8000/api";
// export const API = "http://192.168.1.120:8000/api";
// export const API = "http://192.168.1.213:8000/api";

// export const Storage = "http://localhost:8000/storage";
// export const Storage = "http://192.168.1.50:8000/storage";
// export const Storage = "http://192.168.1.103:8000/storage";
// export const Storage = "http://192.168.1.12:8000/storage";
// export const Storage = "http://192.168.1.213:8000/storage";

// ---------- End API Development ------------------------//

// ---------- API Production ------------------------------//
export const API = "https://maxxsco-api.maxxsgroup.com/api";
export const Storage = "https://maxxsco-api.maxxsgroup.com/storage";
// ---------- End API Production -------------------------//
