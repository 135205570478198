import React from "react";
import { Storage } from "../Config/Config";

export default function Data(props) {
  return (
    <div className="">
      <div className="row row-cols-1 text-center">
        <div className="col-12">
          <p className="fs-4 fw-bold">Customer Profile</p>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 text-start">
        <div className="col-12 col-sm-4 col-md-4 p-2">
          <img
            src={Storage + `/user_profile/${props.id}/${props.photo}`}
            className="rounded mx-auto d-block img-fluid shadow-sm img-thumbnail"
            alt={props.photo}
            style={{ maxWidth: 200 }}
          />
        </div>
        <div className="col-12  col-sm-8 col-md-8 p-3">
          <table className="table table-sm table-responsive text-start">
            <tbody>
              <tr className="align-middle small">
                <td>Name</td>
                <td>
                  {props.firstName} {props.lastName}
                </td>
              </tr>
              <tr className="align-middle small">
                <td>Nationality</td>
                <td>{props.nationality}</td>
              </tr>
              <tr className="align-middle small">
                <td>Gender</td>
                <td>{props.gender}</td>
              </tr>
              <tr className="align-middle small">
                <td>Status</td>
                <td>
                  {props.status === "Active" ? (
                    <span className="badge align-items-center py-2 px-4 my-1 text-success-emphasis bg-success-subtle border border-success-subtle rounded-pill">
                      Active
                    </span>
                  ) : (
                    <span className="badge align-items-center py-2 px-4 my-1 text-success-emphasis bg-danger-subtle border border-danger-subtle rounded-pill">
                      Inactive
                    </span>
                  )}
                </td>
              </tr>
              <tr className="align-middle small">
                <td>Expired Date</td>
                <td>{props.expiredDate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
